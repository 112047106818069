$color-tertiary-light: #2998ff;
$color-tertiary-dark: #232323;

.contact {
  background-color: #232323;
  &_container {
    display: flex;
    justify-content: center;
  }

  &_fixed_size {
    width: 400px;

    // (tab-portsol)
    @media only screen and (max-width: 58.25em) {
      width: 100%;
      margin: 0 3rem;
      margin-bottom: 5rem;
    }
  }

  &_group_input {
    background-color: #232323;
    color: white;
    width: 100%;
    padding: 1rem 1rem;
    box-shadow: none;
    outline: none;
    font-size: 1.5rem;
    font-family: "Roboto", sans-serif;
    position: relative;
    user-select: none;
    border: 1px solid transparent;
    outline: 1px solid rgba(black, 0.5);
  }

  &.disabled &_group_input {
    background-color: #232323;
    cursor: not-allowed;
  }

  &_group {
    position: relative;
  }

  &_group_label {
    position: absolute;
    left: 0;
    top: 1rem;
    transition: all 0.5s ease;
    font-size: 1.5rem;
    pointer-events: none;
    transform: translateX(1rem);
    color: rgb(249, 196, 18);
    background-clip: text;
  }

  &_group_line_container {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    overflow: hidden;
    pointer-events: none;
  }

  &_group_line_container::after {
    content: "";
    display: block;
    position: absolute;
    bottom: 0;
    left: 0;
    height: 0.25rem;
    width: 100%;
    background-color: $color-tertiary-dark;
    transform: translateX(-100%);
  }

  &_group_input:focus,
  &_group_input:not(:placeholder-shown) {
    outline: 1px solid $color-tertiary-light;
    border: 1px solid $color-tertiary-light;
  }

  &_group_input:focus ~ &_group_line_container::after {
    transition: all 0.5s ease;
    transform: translateX(0%);
  }

  &_group_input:focus + &_group_label,
  &_group_input:not(:placeholder-shown) + &_group_label {
    top: -3rem;
    transform: none;
    font-size: 2rem;
  }

  &_group_textarea_container {
    // (tab-portsol)
    @media only screen and (max-width: 58.25em) {
      width: 100%;
      margin: 0 3rem;
    }
  }

  &_group_textarea {
    background-color: #232323;
    color: white;
    resize: none;
    height: 25rem;
    width: calc(800px + 10rem);
    padding: 1rem 1rem;

    // (tab-portsol)
    @media only screen and (max-width: 58.25em) {
      width: 100%;
    }
  }
}
