.ScrapingJobResult_ResultsContent {
  padding: 2rem;
  background-color: #181818;
  height: 100%;
  font-size: 2rem;
  color: red;
  h3 {
    text-align: center;
    padding-top: 2rem;
    padding-bottom: 2rem;
    background-color: #181818;
  }
}

.ScrapingJobResult_ResultsContent {
  padding-top: 0.5rem;
  padding-left: 3rem;
  padding-right: 3rem;
  display: flex;
  flex-direction: column;
  padding-bottom: 1rem;
}

.ScrapingJobResult_ResultsContent > div {
  margin-bottom: 1rem;
}

.ScrapingJobResult_ResultsContent__Count {
  font-size: 2.5rem;
}

.ScrapingJobResult_ResultsContent__textarea {
  margin-top: 0.5rem;
  padding: 2rem;
  width: 100%;
  background: #232323;
  color: white;
  flex: 1;
  resize: none;
}
