.AnalysisJobResult_ResultsContent  {
  background-color: #181818;
  height: 100%;
  font-size: 2rem;
  color: red;
  h3 {
    text-align: center;
    padding-top: 2rem;
    padding-bottom: 2rem;
    background-color: #181818;
  }
}

.AnalysisJobResult_ResultsContent .ChosenResultDisplay {
  table {
    border-collapse:collapse;
  }

  tr {
    border-bottom: 1px solid black;
  }
  td {
    padding: 2rem 4rem;
    background: rgb(133, 12, 12);
    &:nth-child(2) {
      text-align: right;
    }
  }
}


.AnalysisJobResult_ResultsContent .ChosenResultDisplay {
  display:grid;
  grid-auto-flow: column;
}
.AnalysisJobResult_ResultsContent .ChosenResultDisplay > * {
  display: flex;
  align-items: center;
  flex-direction: column;

  width:100%;
  color: #fff;
  font-weight: 700;
  padding-top: 5rem;
  padding-bottom: 5rem;
}
.AnalysisJobResult_ResultsContent .RefusedResultDisplays{
  background-color: #181818;
  padding-left: 2rem;
  padding-right: 2rem;

  display: grid;
  grid-gap: 2rem;

  padding-bottom: 5rem;


  grid-template-columns: repeat(auto-fit, minmax(700px, 1fr));

  @media only screen and (max-width: 110em) {
    grid-template-columns: repeat(auto-fit, minmax(600px, 1fr));
  }
  @media only screen and (max-width: 58.25em) {
    grid-template-columns: repeat(auto-fit, minmax(100%, 1fr));
    display: flex;
    flex-direction: column;
  }

  table {
    border-collapse:collapse;
  }

  tr {
    border-bottom: 1px solid black;
  }
  td {
    padding: 2rem 4rem;
    background: rgb(133, 12, 12);
    &:nth-child(2) {
      text-align: right;
    }
  }
  td {
    color: white;
  }
}
