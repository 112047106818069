.btn {
 color: black;
  background: rgb(249, 196, 18);
  padding: 1rem 2rem;
  border: none;
  border-radius: 5px;
  transition: background, color 0.5s ease;
  font-size: 1.3rem;
  font-family: Arial, Helvetica, sans-serif;
  cursor: pointer;
  &:hover {
    background: darken(rgb(249, 196, 18), 15%);
    color: lighten(black, 25%);
  }

  &_disabled {
    background-color: #222222;
    opacity: 0.5;
    cursor: not-allowed;
    &:hover {
      background-color: #222222;
      color: #fff;
      border: 1px solid transparent;
    }
  }

  &_block {
    width: 90rem;
    display: block;
    margin: auto;

    // (tab-portsol)
    @media only screen and (max-width: 58.25em) {
      width: 75%;
      margin-top: -5rem;
    }
  }
}
