.ResultDisplay {
  border: 2px solid #f9c412;
  height: min-content;
  background-color: #232323;
  
  &_Bar {
    div:nth-child(1) {
      margin-top: 0.25rem;
    }
  }
}
