
@import "./scss/button.scss";

@mixin important-text {
  color: black;
  background: rgb(249, 196, 18);
  padding: 1rem 2rem;
  border: none;
  border-radius: 5px;
  transition: background, color 0.5s ease;
  font-size: 1.3rem;
  font-family: Arial, Helvetica, sans-serif;
  cursor: pointer;
  &:hover {
    background: darken(rgb(249, 196, 18), 15%);
    color: lighten(black, 25%);
  }
}

// Utilities

.AccountCreator {
  background: black;
  min-height: 100vh;
  .my-1 {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
  .my-2 {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }
  .my-3 {
    padding-top: 3rem;
    padding-bottom: 3rem;
  }

  .my-4 {
    padding-top: 4rem;
    padding-bottom: 4rem;
  }

  .mt-6 {
    padding-top: 6rem;
  }
  .mt-8 {
    padding-top: 8rem;
  }

  .mb-8 {
    padding-bottom: 4rem;
  }

  .mb-5-removable {
    padding-bottom: 5rem;
    // (tab-portsol)
    @media only screen and (max-width: 58.25em) {
      padding-bottom: 0rem;
    }
  }
  .border {
    position: relative;
    padding: 10px;
    border: 4px solid #777;
    width: calc(800px + 13rem);;
    background-color: #232323;
    left: 50%;
    transform: translate(-50%, 0%);
  }

  button {
    @include important-text();
  }
  .agency {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    cursor: pointer;

    &:hover {
      .agency_text {
        color: darken(rgb(249, 196, 18), 15%);
      }
    }
    &_text {
      font-size: 1.75rem;
      color: rgb(249, 196, 18);
    }
  }

  .button-group {
    margin-top: 0.5rem;
    display: flex;
    justify-content: center;
    & > * {
      width: 44rem;
      &:first-child {
        margin-right: 2.5rem;
      }
    }
  }

  .goToScrapingDesk {
    position: absolute;
    transform: translate(-50%, -50%);
    top: 10rem;
  }
}
