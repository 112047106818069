$color-grey-light-1: #f7f7f7;
$color-tertiary-light: #2998ff;
$color-tertiary-dark: #5643fa;

.customModalOverride {
  .MuiDialog-container {
    width: 100vw;
    background: transparent;
    margin: 0;
  }

  .MuiPaper-root {
    margin: 0;
    box-shadow: none;
    background: transparent;
    max-width: unset;
    max-height: unset;
    display: flex;
    justify-content: center;
    align-items: center;
    animation: 1s slidefrombottom ease;
  }
}

.fadeaway {
  .MuiPaper-root {
    animation: 1s slidefrombottomReverse ease;
  }
}

.modal {
  &.slideAway {
    pointer-events: none;
  }

  &_container {
    background: black;
    border-radius: 10px;
    box-shadow: 0 1rem 4rem rgba(black, 0.2);
    position: relative;
    margin: 0 3rem;
    overflow: hidden;
  }

  &_top {
    background: #63656b;

    height: 4rem;
    display: flex;
    align-items: center;
  }

  &_circle {
    margin-left: 1rem;
    height: 1.5rem;
    width: 1.5rem;
    display: inline-block;
    border-radius: 50%;
  }

  &_circle_red {
    background-color: #ff5f57;
  }

  &_circle_yellow {
    background-color: #ffbd2e;
  }

  &_circle_green {
    background-color: #2ace42;
  }

  &_body {
    padding: 3rem 9rem 3rem 9rem;

    // (phone-custom)
    @media only screen and (max-width: 49em) {
      padding-left: 3rem;
      padding-right: 3rem;
    }
  }

  &_close {
    height: 2.5rem;
    width: 2.5rem;

    .vanilla_item {
      fill: white;
      cursor: pointer;
    }
    position: absolute;
    right: 0.75rem;
    top: 0.75rem;

    &:hover {
      .vanilla_item {
        fill: goldenrod;
      }
    }
  }

  &_title {
    font-weight: 800;
    display: inline-block;
    text-align: right;
    color: $color-grey-light-1;
    width: 20rem;
    padding-right: 2rem;

    // (phone-custom)
    @media only screen and (max-width: 49em) {
      width: initial;
      padding-right: 1rem;
    }

  }

  &_text {
    color: #fff;
    padding: 0.5rem 2rem;
    font-size: 1.5rem;
    letter-spacing: 0.05rem;
    color: $color-tertiary-light;
    line-height: 1.7;

    cursor: pointer;

    &:hover {
      background: $color-tertiary-dark;
    }
  }
}

@keyframes slidefrombottom {
  0% {
    transform: translateX(150%) scale(0.1);
    opacity: 0;
  }

  90% {
    transform: translateX(-2%);
  }

  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes slidefrombottomReverse {
  0% {
    transform: translateX(0%);
  }

  20% {
    transform: translateX(30%);
  }

  100% {
    transform: translateX(-150%) scale(0.1);
    opacity: 0;
  }
}
