*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}

html {
  font-size: 62.5%;

  // (tab-land)
  @media only screen and (max-width: 75em) {
    font-size: 56.25%;
  }

  // (tab-port)
  @media only screen and (max-width: 56.25em) {
    font-size: 50%;
  }

}

body {
  box-sizing: border-box;
  font-family: "Roboto", sans-serif;
}

// @mixin respond($breakpoint) {
//   @if $breakpoint == phone {
//     //600px
//     @media only screen and (max-width: 37.5em) {
//       @content;
//     }
//   }

//   @if $breakpoint == phone-custom {
//     //900px
//     @media only screen and (max-width: 49em) {
//       @content;
//     }
//   }

//   @if $breakpoint == tab-port {
//     //900px
//     @media only screen and (max-width: 56.25em) {
//       @content;
//     }
//   }

//   @if $breakpoint == tab-portsol {
//     //900px
//     @media only screen and (max-width: 58.25em) {
//       @content;
//     }
//   }

//   //1200px
//   @if $breakpoint == tab-land {
//     @media only screen and (max-width: 75em) {
//       @content;
//     }
//   }

//   //1800px+
//   @if $breakpoint == big-desktop {
//     @media only screen and (min-width: 112.5em) {
//       @content;
//     }
//   }
// }