.ContextActions {
  display: grid;
  grid-template-columns: min-content min-content 300px;
  align-items: center;
}
.ContextActions .StartAnalysisButton {
  scale: 80%;
  margin-bottom: 0px;
}
.ContextActions .MoreActionsButton {
  background: none;
  color: #eee;
}
.ContextActions .MoreActionsButton:hover {
  color: #eee;
  background: none;
}
.context_combobox {
  font-size: 2rem;
}
