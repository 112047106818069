.Message{
  display: grid;
  grid-template-columns: 1fr max-content min-content;
  grid-column-gap: 5px;
  align-items: center;
}

.Message .MessageDeleteButton{
  transition: opacity 200ms;
  margin: 2px 0px 0px 0px;
  opacity: 0;
}
.Message:hover .MessageDeleteButton{
  opacity: 0.4;
}

.Message .MessageText{
  font-size: 16px;
  grid-column: 1/3;
  margin-top: 0px;
  white-space: break-spaces;
}

.Message .MessageWho{
  font-size: 14px;
}

.Message .MessageWhen{
  font-size: 14px;
}
.Message.Expired .MessageWhen{
  color: #aaa;
}
