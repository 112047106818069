.MessageThread .MessageThreadOpenButton{
  padding: 0px;
}
.MessageThreadDialog .NewMessageBox{
  width: 100%;
  display: grid;
  grid-template-columns: 1fr min-content;
  grid-column-gap: 6px;
  align-items: center;
  font-size: 16px;
}
.MessageThreadDialog .NewMessageBox .MessageInput{
  margin: 0px;
}
