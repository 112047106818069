.form {
  &_flexbox {
    display: flex;
    justify-content: center;

    // (tab-portsol)
    @media only screen and (max-width: 58.25em) {
      flex-direction: column;
    }
  }

  &_flexbox > *:first-child {
    padding-right: 10rem;

    // (tab-portsol)
    @media only screen and (max-width: 58.25em) {
      padding-right: 0;
    }
  }
}
