.Context {
  background-color: #232323;
  padding: 10px;
  border: 4px solid #777;
  display: grid;
  grid-template-columns: min-content 1fr;
  grid-column-gap: 15px;
  grid-row-gap: 15px;
}
.Context.Running {
  border-color: #f9c412;
}
.Context.Finished {
  border-color: #53a436;
}
.Context.Failed {
  border-color: #c13232;
}
.Context .Description {
  display: grid;
  grid-auto-flow: row;
  text-align: left;
  grid-row-gap: 8px;
}
.Context .Description > div {
  width: max-content;
}
.Context .Description .FirstRow {
  width: 100%;
  display: grid;
  grid-template-columns: max-content 1fr;
}
.Context .Description .FirstRow .ContextDetails {
  justify-self: end;
  display: grid;
  grid-auto-flow: column;
  grid-column-gap: 2px;
}
.Context .Description .SecondRow {
  display: grid;
  grid-template-columns: 1fr min-content;
  width: 100%;
}
.Context .TopLeftColumn .DayCount {
  width: min-content;
  height: min-content;
  padding: 0px 10px 3px 10px;
  grid-row-start: 0;
  margin-bottom: 5px;
}
.Context .Actions {
  grid-column: 1/-1;
  width: 100%;
}
.tooltip_span {
  font-size: 1.5rem;
  line-height: 1.5rem;
}
