.display {
  background-color: black;
  padding-top: 2rem;
  display: flex;
  justify-content: center;
  &_title {
    font-size: 2.5rem;
    letter-spacing: 0.1rem;
    position: relative;
    color: rgb(249, 196, 18);
    background-clip: text;
    padding-bottom: 2rem;

    border-bottom: 2px solid darken(rgb(249, 196, 18), 15%);

    &::before {
      background-color: rgb(249, 196, 18);
      content: "";
      height: 3px;
      width: 20px;
      display: inline-block;
      position: absolute;
      top: 30%;
      left: -25%;
      transform: translateY(-50%);
    }

    &::after {
      content: "";
      height: 3px;
      width: 20px;
      background-color: rgb(249, 196, 18);
      display: inline-block;
      position: absolute;
      top: 30%;
      right: -25%;
      transform: translateY(-50%);
    }
  }
}
